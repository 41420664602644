import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/RassrochkaNaAuto"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { MoreBenefitsRedisign } from "../components/MorBenefitsRedisgn"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"
import { BottomCta, RefinSeoBgImg } from "../components/BottomCta"
import { Partners } from "../components/PartnersNew"
import { NewFooter } from "../components/NewFooter"

import { getPageData } from "../helpers/getPageData"

import { PageData } from "../interfaces/pageProps"
import { OtherCards } from "../components/OtherCards"
import { autoPromo } from "../components/PartnersNew/helpers"
import { Questions } from "../components/Questions"
import { QUESTIONS_FOR_RASSROCHKA_NA_AUTO } from "../components/Questions/helpers"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const bannerTitle = "Рассрочка на авто с картой «Халва»"

const bannerDescription = "Покупки разные — карта одна"

const subTitlePartners =
  "Рассрочка 24 месяца в первый месяц использования Халвы – у всех партнеров. Со второго месяца срок рассрочки на новые покупки устанавливает партнер:"

const GIFT_TITLE = (
  <>
    Оформите Халву и получите
    <br />
    <b> месяц&nbsp;подписки&nbsp;в&nbsp;подарок</b>
  </>
)

// Время для таймер(30мин)
const countDownTime = 1800000

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader showHint={false} hasCTA countDownTime={countDownTime} />
      <Banner title={bannerTitle} description={bannerDescription} orderNum="1" />
      <FourAdvantagesPoints
        withTitle
        indent={false}
        variant="rassrochkaNaAuto"
        title="Преимущества с Халвой:"
        orderNum="2"
      />
      <HowInstallmentWorks
        headTitle="Как получить рассрочку на&nbsp;24&nbsp;месяца"
        variant="blackFridayHalva"
        orderNum="4"
      />
      <FormPKW
        withTimer
        dataLayerName="shortPersonalForm"
        orderNum="3"
        countDownTime={countDownTime}
        progressBar
        isGreenApprove
        fioPlaceHolder="Фамилия Имя Отчество"
        title={titleForm}
      />
      <NewCalculator variant="inviteFriend" additionalEventInDataLayer orderNum="4" />
      <OtherCards orderNum="5" />
      <Partners
        title="Более 250&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="6"
        additionalPartners={autoPromo}
      />
      <MoreBenefitsRedisign
        additionalEventInDataLayer
        orderNum="7"
        hasSubtitle
        variant="pkwItemsNewHint"
        bottomBlock={false}
      />
      <BottomCta variant="inviteFriend" title={GIFT_TITLE} BgImg={RefinSeoBgImg} orderNum="8" />
      <Questions
        questionList={QUESTIONS_FOR_RASSROCHKA_NA_AUTO}
        additionalEventInDataLayer
        orderNum="9"
      />
      <NewFooter ligal={ligal} orderNum="10" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/rassrochka-na-avto/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
